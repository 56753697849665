// Your variable overrides
// $body-bg: #000;
// $body-color: #111;

// Bootstrap and its default variables

// $primary: rgb(183, 220, 143);
// $secondary: rgb(29, 60, 52);
// $white: $light;

// scss-docs-start theme-color-variables
$primary:       rgb(183, 220, 143);
$secondary:     rgb(72, 162, 63);
$info:          rgb(255,195,88);
$light:         rgb(240, 244, 227);
$dark:          rgb(29, 60, 52);
// $success:       $green !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $dark:          $gray-900 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
// $theme-colors: (
//   "primary":    $primary,
//   "secondary":  $secondary,
//   "success":    $success,
//   "info":       $info,
//   "warning":    $warning,
//   "danger":     $danger,
//   "light":      $light,
//   "dark":       $dark
// );
$theme-colors: (
  'primary': rgb(183, 220, 143),
  'secondary': rgb(72, 162, 63),
  'info': rgb(255,195,88),
  'light': rgb(240, 244, 227),
  'dark': rgb(29, 60, 52),
);

@import '~bootstrap/scss/bootstrap';