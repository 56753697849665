#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);  
}

.login-container {
  height: calc(100vh - 76px);
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}

.login-jumbo {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  img.av-logo {
    height: 2em;
    width: auto;
    display: inline-block;
  }

  h1 {
    line-height: 2em;
    font-size: 2em;
  }
}
